import { tableFontSizes } from "../../../../shared/tableFontSize";

export const columnNames = [
  {
    key: "select",
    label: "",
    sorter: false,
    filter: false,
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "track_number",
    label: "Номер груза",
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "cargo_title",
    label: "Название груза",
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "cargo_status",
    label: "Статус груза",
    _style: { fontSize: tableFontSizes.HEADER },
  },

  {
    key: "registration_date",
    label: "Дата оформления",
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "destination",
    label: "Пункт доставки",
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "price",
    label: "Стоимость",
    _style: { fontSize: tableFontSizes.HEADER },
  },
  {
    key: "paid",
    label: "Оплата",
    _style: { fontSize: tableFontSizes.HEADER },
  },
];
