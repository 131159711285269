import { ClientsTypeProps } from "../../client/model/client.model";
import { z } from "zod";
import { ShipmentHistory } from "../../shipment/model/shipment.model";

export type CargoByID = {
  cargo: {
    data: {
      attributes: {
        name: string;
        destination: string;
        link_product: string;
        track_number: string;
        registration_date: string;
        shipment_method: string;
        location: string;
        cargo_title: string;
        size: string;
        description: string;
        weight: string;
        price: string;
        origin_country: string;
        origin_address: string;
        destination_county: string;
        destination_address: string;
        cargo_number: string;
        paid: boolean;
        pack_type: string;
        isPending: boolean;
        sender: {
          data: ClientData<Pick<ClientsTypeProps, "name" | "id">>[];
        };
        recipient: {
          data: ClientData<Pick<ClientsTypeProps, "name" | "id">>[];
        };
        cargo_status: {
          data: {
            attributes: {
              name: string;
              country: string;
              color: string;
            };
          };
        };
        shipment_history: {
          data: ShipmentHistory[];
        };
      };
    };
  };
};

type Cargo = {
  id: string;
  attributes: {
    track_number: string;
    registration_date: string;
    cargo_title: string;
    price: string;
    paid: boolean;
    destination_county: string;
    destination_address: string;
    cargo_number: string;
    isPending: boolean;
    pack_type: string;
  };
};

export type CargoPendingQueryResponse = {
  cargos: {
    data: Cargo[];
  };
};
type ClientData<T> = {
  id: string;
  attributes: T;
};

export type Cargos<T extends object> = {
  cargos: {
    data: CargoItem<T>[];
  };
};

type CargoItem<T> = {
  id: string;
  attributes: T;
};

type CargoAttributes = {
  link_product: string;
  track_number: string;
  registration_date: string;
  shipment_method: string;
  cargo_title: string;
  size: string;
  description: string;
  weight: string;
  price: string;
  origin_country: string;
  origin_address: string;
  destination_county: string;
  destination_address: string;
  cargo_number: string;
  paid: boolean;
  inCargoPlace: boolean;
  sender: {
    data: ClientData<Pick<ClientsTypeProps, "name" | "id">>[];
  };
  recipient: {
    data: ClientData<Pick<ClientsTypeProps, "name" | "id">>[];
  };
  cargo_status: {
    data: {
      attributes: {
        name: string;
        country: string;
        color: string;
      };
    };
  };
};

export type CreateCargoPlaceTableItem = Cargos<
  Pick<
    CargoAttributes,
    | "track_number"
    | "cargo_title"
    | "registration_date"
    | "destination_county"
    | "destination_address"
    | "price"
    | "paid"
    | "inCargoPlace"
    | "cargo_status"
  >
>;

export const addCargoPlaceSchema = z.object({
  type: z
    .string()
    .nonempty("Поле является объязательным")
    .min(2, "Выберите тип отправления"),
  number: z
    .string()
    .nonempty("Поле является обязательным")
    .min(1, "Поле является обязательным"),
});

export type CargoPlaceByIDAttributes = Cargos<
  Pick<
    CargoAttributes,
    | "track_number"
    | "cargo_title"
    | "registration_date"
    | "destination_county"
    | "destination_address"
  >
>;

export type CreateCargoPlaceCargoItem = {
  [key: string]: number | string | any;
};
