export enum ROUTES {
  MAIN = "/",
  STATUSES = "/statuses",
  CLIENTS = "/clients",
  CARGOES = "/cargoes",
  CARGO = "/cargoes/cargo/",
  EDIT_CARGO = "/cargoes/cargo/editCargo/",
  ADD_CARGO = "/add-cargo",
  SHIPMENTS = "/cargoDispatch",
  SHIPMENT = "/cargoDispatch/shipment/",
  CARGO_PLACE_IN_SHIPMENT = "/cargoDispatch/shipment/cargoPlaceDetail/",
  ADD_SHIPMENT = "/addShipment",
  ADD_CARGO_PLACE = "/addCargoPlace",
  SHIPMENT_ACTIVITY = "/shipmentActivity/",
  DISBAND_CARGOES_TABLE = "/disbandCargoesTable/",
  NEW_SHIPMENT = "/newShipment/",
  USERS = "/users-setup",
  EDIT_USER = "/users-setup/edit-user/",
  ADD_USER = "/users-setup/add-user/",
}
