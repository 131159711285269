import { GraphQLClient } from 'graphql-request'

const token = localStorage.getItem('jwt')

const requestHeaders = {
  authorization: 'Bearer ' + token,
}

export const graphqlRequestClient = new GraphQLClient(
  process.env.REACT_APP_GQL_BASE_URL as string,
  {
    headers: requestHeaders,
  },
)
