import { CBadge } from "@coreui/react-pro";
import React from "react";

export const paidBadge = (status: boolean | undefined | string) => {
  return (
    <td>
      {typeof status === "string" ? (
        status === "true" ? (
          <CBadge color={"secondary"}>
            <div
              className={"d-flex align-items-center gap-2"}
              style={{ height: 12 }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  width: 10,
                  height: 10,
                  borderRadius: 100,
                }}
              ></div>
              <div>{"Оплачен"}</div>
            </div>
          </CBadge>
        ) : (
          <CBadge color={"secondary"}>
            <div
              className={"d-flex align-items-center gap-2"}
              style={{ height: 12 }}
            >
              <div
                style={{
                  backgroundColor: "red",
                  width: 10,
                  height: 10,
                  borderRadius: 100,
                }}
              ></div>
              <div>{"Не оплачен"}</div>
            </div>
          </CBadge>
        )
      ) : status ? (
        <CBadge color={"secondary"}>
          <div
            className={"d-flex align-items-center gap-2"}
            style={{ height: 12 }}
          >
            <div
              style={{
                backgroundColor: "green",
                width: 10,
                height: 10,
                borderRadius: 100,
              }}
            ></div>
            <div>{"Оплачен"}</div>
          </div>
        </CBadge>
      ) : (
        <CBadge color={"secondary"}>
          <div
            className={"d-flex align-items-center gap-2"}
            style={{ height: 12 }}
          >
            <div
              style={{
                backgroundColor: "red",
                width: 10,
                height: 10,
                borderRadius: 100,
              }}
            ></div>
            <div>{"Не оплачен"}</div>
          </div>
        </CBadge>
      )}
    </td>
  );
};
