import { gql } from "graphql-request";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GraphQLResponse } from "graphql-request/build/esm/types";
import { graphqlRequestClient } from "../../../requestClient";
import {
  CargoPlaceID,
  CargoPlacesTableItems,
} from "../../../entities/cargoPlace/model/cargoPlace.model";
import {
  useCargoPendingUpdatingById,
  useChangeInCargoPlaceStatus,
  useChangeInCargoPlaceStatusAndAuthor,
} from "../../../views/cargo/api/cargo.api";
import { data } from "../../../views/cargoDispatch/data";
import cargo from "../../../views/cargo/Cargo";
import { useNavigate, useParams } from "react-router-dom";

const addCargoPlace = gql`
  mutation CreateLogisticHub($number: String!, $type: String!, $cargos: [ID]) {
    createLogisticHub(
      data: { number: $number, place_type: $type, cargos: $cargos }
    ) {
      data {
        id
        attributes {
          number
          place_type
          cargos {
            data {
              id
              attributes {
                cargo_title
              }
            }
          }
        }
      }
    }
  }
`;

const addCargoPlaceAndAuthor = gql`
  mutation CreateLogisticHub(
    $number: String!
    $type: String!
    $cargos: [ID]
    $author: String!
  ) {
    createLogisticHub(
      data: {
        number: $number
        place_type: $type
        cargos: $cargos
        author: $author
      }
    ) {
      data {
        id
        attributes {
          author
          number
          place_type
          cargos {
            data {
              id
              attributes {
                cargo_title
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  number: string;
  type: string;
  cargos: string[];
};
export const useAddCargoPlace = () => {
  const queryClient = useQueryClient();
  const { mutate } = useChangeInCargoPlaceStatus();
  return useMutation<GraphQLResponse, Error, Props>(
    (data) => graphqlRequestClient.request(addCargoPlace, data),

    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("cargoPlaces");
        variables.cargos.forEach((cargo) =>
          mutate({ id: cargo, status: true }),
        );
      },
    },
  );
};

type PropsByAuthor = {
  number: string;
  type: string;
  cargos: string[];
  author: string;
};
export const useAddCargoPlaceAndAuthor = () => {
  const queryClient = useQueryClient();
  const { mutate } = useChangeInCargoPlaceStatus();
  return useMutation<GraphQLResponse, Error, PropsByAuthor>(
    (data) => graphqlRequestClient.request(addCargoPlaceAndAuthor, data),

    {
      // onSuccess: (data, variables, context) => {
      //   queryClient.invalidateQueries("cargoPlaces");
      //   variables.cargos.forEach((cargo) =>
      //     mutate({ id: cargo, status: true, pendingAuthor: author as string }),
      //   );
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("cargoPlaces");
        variables.cargos.forEach((cargo) =>
          mutate({ id: cargo, status: true }),
        );
      },
    },
  );
};

const getCargoPlaces = gql`
  query LogisticHubs {
    logisticHubs {
      data {
        id
        attributes {
          number
          place_type
          createdAt
          cargos {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const getCargoPlacersForTable = gql`
  query LogisticHubs {
    logisticHubs(
      filters: { inShipment: { eq: false } }
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          number
          inShipment
          place_type
          cargos {
            data {
              id
              attributes {
                shipment_history {
                  data {
                    id
                    attributes {
                      createdAt
                      destination_country
                      destination_address
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getFreeCargoPlacersForTableByAuthor = gql`
  query LogisticHubs($author: String!) {
    logisticHubs(
      filters: { inShipment: { eq: false }, author: { eq: $author } }
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          number
          inShipment
          place_type
          cargos {
            data {
              id
              attributes {
                shipment_history {
                  data {
                    id
                    attributes {
                      createdAt
                      destination_country
                      destination_address
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const changeCargoPlaceStatus = gql`
  mutation UpdateLogisticHub($id: ID!, $inShipment: Boolean!) {
    updateLogisticHub(id: $id, data: { inShipment: $inShipment }) {
      data {
        id
        attributes {
          inShipment
        }
      }
    }
  }
`;

const getCargoPlaceByID = gql`
  query LogisticHub($id: ID!) {
    logisticHub(id: $id) {
      data {
        id
        attributes {
          place_type
          number
          inShipment
          createdAt
          cargos {
            data {
              id
              attributes {
                track_number
                registration_date
                cargo_title
                destination_county
                destination_address
              }
            }
          }
        }
      }
    }
  }
`;

const getIsNotShipmentCargoPlace = gql`
  query LogisticHubs {
    logisticHubs(filters: { inShipment: { eq: false } }) {
      data {
        id
        attributes {
          place_type
          number
          inShipment
          createdAt
          updatedAt
          cargos {
            data {
              attributes {
                destination_county
                destination_address
              }
            }
          }
        }
      }
    }
  }
`;
type Cargo = {
  attributes: {
    destination_country: string;
    destination_address: string;
  };
};

type LogisticHub = {
  id: string;
  attributes: {
    place_type: string;
    number: string;
    inShipment: boolean;
    createdAt: string;
    updatedAt: string;
    cargos: {
      data: Cargo[];
    };
  };
};

type LogisticHubsData = {
  logisticHubs: {
    data: LogisticHub[];
  };
};
export const useGetIsNotShipmentCargoPlaces = () => {
  return useQuery<GraphQLResponse, Error, LogisticHubsData>({
    queryKey: "isNotShipmentCargoPlaces",
    queryFn: async () => {
      return graphqlRequestClient.request(getIsNotShipmentCargoPlace);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetAllCargoPlaces = () => {
  return useQuery<GraphQLResponse, Error, CargoPlacesTableItems>({
    queryKey: "cargoPlaces",
    queryFn: async () => {
      return graphqlRequestClient.request(getCargoPlaces);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetFreeCargoPlaces = () => {
  return useQuery<GraphQLResponse, Error, CargoPlacesTableItems>({
    queryKey: "cargoPlaces",
    queryFn: async () => {
      return graphqlRequestClient.request(getCargoPlacersForTable);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetFreeCargoPlacesByAuthor = () => {
  const author = localStorage.getItem("userName");
  return useQuery<GraphQLResponse, Error, CargoPlacesTableItems>({
    queryKey: "cargoPlaces",
    queryFn: async () => {
      return graphqlRequestClient.request(getFreeCargoPlacersForTableByAuthor, {
        author: author as string,
      });
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

const deleteCargoPlace = gql`
  mutation DeleteLogisticHub($id: ID!) {
    deleteLogisticHub(id: $id) {
      data {
        id
      }
    }
  }
`;
type DeleteProps = {
  id: string;
  cargos: { id: string }[];
};

export const useDeleteCargoPlace = () => {
  const queryClient = useQueryClient();
  const { mutate } = useChangeInCargoPlaceStatus();
  return useMutation<GraphQLResponse, Error, DeleteProps>(
    (data) => graphqlRequestClient.request(deleteCargoPlace, data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("cargoPlaces");
        variables.cargos.forEach((item) =>
          mutate({ id: item.id, status: false }),
        );
      },
    },
  );
};

type DisbandProps = {
  id: string;
  cargos: { id: string }[];
};

export const useDisbandCargoPlace = () => {
  const params = useParams();
  const oldShipmentId = params.id;
  const navigate = useNavigate();
  const { mutate: setIsPending } = useCargoPendingUpdatingById();
  const queryClient = useQueryClient();
  const author = localStorage.getItem("userName");
  return useMutation<GraphQLResponse, Error, DeleteProps>(
    (data) => graphqlRequestClient.request(deleteCargoPlace, data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("cargoPlaces");
        variables.cargos.forEach((item) =>
          setIsPending({
            id: item.id,
            isPending: true,
            pendingAuthor: author as string,
          }),
        );
        navigate(`/disbandCargoesTable/${oldShipmentId}`);
      },
    },
  );
};

type ChangeCargoPlaceStatus = {
  id: string;
  inShipment: boolean;
};

export const useChangeCargoPlaceStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<GraphQLResponse, Error, ChangeCargoPlaceStatus>(
    (data) => graphqlRequestClient.request(changeCargoPlaceStatus, data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("cargoPlaces");
      },
    },
  );
};

export const useGetCargoPlaceByID = (id: string) => {
  return useQuery<GraphQLResponse, Error, CargoPlaceID>({
    queryKey: "cargoPlaceByID",
    queryFn: async () => {
      return graphqlRequestClient.request(getCargoPlaceByID, { id: id });
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};
