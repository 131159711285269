import { gql } from "graphql-request";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { graphqlRequestClient } from "../../../requestClient";
import { GraphQLResponse } from "graphql-request/build/cjs/types";
import { AddCargoFormData } from "../AddCargo";
import { CargoFormProps } from "../../CargoCorrection/service/CargoType";
import {
  CargoByID,
  CargoPendingQueryResponse,
  CreateCargoPlaceTableItem,
} from "../../../entities/cargo/model/cargo.model";
import { useNavigate } from "react-router-dom";

export type Data = {
  cargos: Cargos;
};

export type Cargos = {
  data: CargoData[];
};

export type CargoData = {
  id: string;
  attributes: CargoAttributes;
};

export type CargoAttributes = {
  track_number: string;
  registration_date: string;
  cargo_title: string;
  price: string;
  paid: boolean;
  destination_county: string;
  destination_address: string;
  cargo_number: string;
  current_shipment: string;
  pack_type: string;
  cargo_status: {
    data: {
      id: string;
      attributes: {
        name: string;
        country: string;
        color: string;
      };
    };
  };
};

export type CargoStatus = {
  data: CargoStatusData;
};

export type CargoStatusData = {
  attributes: CargoStatusAttributes;
};

export type CargoStatusAttributes = {
  name: string;
  country: string;
};
////////////////

const cargos = gql`
  query Cargos {
    cargos(pagination: { limit: 100 }) {
      data {
        id
        attributes {
          track_number
          registration_date
          cargo_title
          price
          paid
          destination_county
          destination_address
          cargo_number
          current_shipment
          pack_type
          cargo_status {
            data {
              id
              attributes {
                name
                country
                color
              }
            }
          }
        }
      }
    }
  }
`;
const cargoPendingUpdating = gql`
  mutation UpdateCargo($id: ID!) {
    updateCargo(id: $id, data: { isPending: true }) {
      data {
        attributes {
          isPending
        }
      }
    }
  }
`;
const cargoMutation = gql`
  mutation CreateCargo(
    $sender: [ID]!
    $recipient: [ID]!
    $origin_country: String!
    $origin_address: String!
    $destination_county: String!
    $destination_address: String!
    $cargo_number: String!
    $weight: String!
    $size: String!
    $link_product: String
    $price: String!
    $description: String!
    $cargo_title: String!
    $track_number: String!
    $registration_date: String!
    $paid: Boolean!
    $pack_type: String!
    $cargo_status: ID!
  ) {
    createCargo(
      data: {
        sender: $sender
        #sender: "1"
        recipient: $recipient
        #recipient: "2"
        origin_country: $origin_country
        origin_address: $origin_address
        destination_county: $destination_county
        destination_address: $destination_address
        cargo_number: $cargo_number
        weight: $weight
        size: $size
        link_product: $link_product
        price: $price
        description: $description
        cargo_title: $cargo_title
        track_number: $track_number
        registration_date: $registration_date
        paid: $paid
        current_shipment: "На складе"
        pack_type: $pack_type
        cargo_status: $cargo_status
      }
    ) {
      data {
        id
        attributes {
          origin_country
          origin_address
          destination_county
          destination_address
          cargo_number
          current_shipment
          sender {
            data {
              id
            }
          }
          recipient {
            data {
              id
            }
          }
          track_number
          weight
          size
          link_product
          price
          paid
          description
          registration_date
          cargo_title
          pack_type
          cargo_status {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const cargoCorrectionMutation = gql`
  mutation UpdateCargo(
    $cargo_title: String
    #$location: String
    # $destination: String
    # $shipment_method: String
    $price: String!
    $paid: Boolean!
    $weight: String!
    $description: String!
    $size: String!
    $registration_date: String
    $link_product: String
    $pack_type: String!
    $id_cargo: ID!
    $sender: [ID]
    $recipient: [ID]
    $cargo_status: ID!
  ) {
    updateCargo(
      data: {
        cargo_title: $cargo_title
        # location: $location
        # destination: $destination
        description: $description
        # shipment_method: $shipment_method
        price: $price
        paid: $paid
        weight: $weight
        size: $size
        registration_date: $registration_date
        link_product: $link_product
        sender: $sender
        recipient: $recipient
        pack_type: $pack_type
        cargo_status: $cargo_status
      }
      id: $id_cargo
    ) {
      data {
        attributes {
          cargo_title
          # destination
          # location
          # shipment_method
          registration_date
          size
          description
          weight
          price
          paid
          link_product
          pack_type
          cargo_status {
            data {
              attributes {
                name
              }
            }
          }
          sender {
            data {
              id
            }
          }
          recipient {
            data {
              id
            }
          }
        }
        id
      }
    }
  }
`;

const cargoByID = gql`
  query Cargo($id: ID) {
    cargo(id: $id) {
      data {
        attributes {
          link_product
          track_number
          registration_date
          cargo_title
          size
          description
          weight
          price
          origin_country
          origin_address
          destination_county
          destination_address
          cargo_number
          paid
          pack_type
          sender {
            data {
              id
              attributes {
                name
                email
                phone
              }
            }
          }
          recipient {
            data {
              attributes {
                name
              }
            }
          }
          shipment_history {
            data {
              id
              attributes {
                shipment_number
                isFinished
                registration_date
                origin_country
                origin_address
                destination_country
                destination_address
                shipment_method
              }
            }
          }
        }
      }
    }
  }
`;

const createCargoPlaceTableItem = gql`
  query Cargos {
    cargos(filters: { inCargoPlace: { eq: false } }) {
      data {
        id
        attributes {
          track_number
          registration_date
          cargo_title
          price
          paid
          destination_county
          destination_address
          cargo_number
          inCargoPlace
          cargo_status {
            data {
              id
              attributes {
                name
                country
                color
              }
            }
          }
        }
      }
    }
  }
`;

const changeInCargoPlaceStatus = gql`
  mutation UpdateCargo($id: ID!, $status: Boolean!) {
    updateCargo(id: $id, data: { inCargoPlace: $status }) {
      data {
        id
        attributes {
          inCargoPlace
        }
      }
    }
  }
`;

const changeInCargoPlaceStatusAndAuthor = gql`
  mutation UpdateCargo($id: ID!, $status: Boolean!, $pendingAuthor: String!) {
    updateCargo(
      id: $id
      data: { inCargoPlace: $status, pendingAuthor: $pendingAuthor }
    ) {
      data {
        id
        attributes {
          inCargoPlace
        }
      }
    }
  }
`;

const changeCargoStatus = gql`
  mutation UpdateCargo($id: ID!, $current_shipment: String!) {
    updateCargo(id: $id, data: { current_shipment: $current_shipment }) {
      data {
        id
        attributes {
          current_shipment
        }
      }
    }
  }
`;

const updateCargoShipmentHistory = gql`
  mutation UpdateCargo($id: ID!, $shipment_history: [ID!]) {
    updateCargo(id: $id, data: { shipment_history: $shipment_history }) {
      data {
        attributes {
          shipment_history {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
const cargoesByCargoPlaceId = gql`
  query LogisticHub($id: ID!) {
    logisticHub(id: $id) {
      data {
        attributes {
          cargos {
            data {
              attributes {
                link_product
                track_number
                registration_date
                cargo_title
                size
                description
                weight
                price
                paid
                dimensions
                origin_country
                origin_address
                destination_county
                destination_address
                cargo_number
                inCargoPlace
                current_shipment
                createdAt
                updatedAt
                isPending
              }
            }
          }
        }
      }
    }
  }
`;

const pendingCargoes = gql`
  query Cargos {
    cargos(filters: { isPending: { eq: true } }) {
      data {
        id
        attributes {
          track_number
          registration_date
          cargo_title
          price
          paid
          destination_county
          destination_address
          cargo_number
          isPending
          pack_type
        }
      }
    }
  }
`;

const pendingCargoesByAuthor = gql`
  query Cargos($pendingAuthor: String!) {
    cargos(
      filters: {
        isPending: { eq: true }
        pendingAuthor: { eq: $pendingAuthor }
      }
    ) {
      data {
        id
        attributes {
          track_number
          registration_date
          cargo_title
          price
          paid
          destination_county
          destination_address
          cargo_number
          isPending
          pack_type
        }
      }
    }
  }
`;

const pendingCargoesStatus = gql`
  mutation UpdateCargo($id: ID!, $isPending: Boolean, $pendingAuthor: String!) {
    updateCargo(
      id: $id
      data: { isPending: $isPending, pendingAuthor: $pendingAuthor }
    ) {
      data {
        id
        attributes {
          isPending
          pendingAuthor
        }
      }
    }
  }
`;
const shipmentHistoryByCargoId = gql`
  mutation UpdateCargo($id: ID!, $newShipmentHistory: [ID!]!) {
    cargo: getCargo(id: $id) {
      shipment_history {
        data {
          id
        }
      }
    }
    updatedCargo: updateCargo(
      id: $id
      data: {
        shipment_history: {
          data: {
            # Concatenate the existing shipment history with the new data
            id: $newShipmentHistory
          }
        }
      }
    ) {
      data {
        id
        attributes {
          shipment_history {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const updateCargoCurrentShipmentAndHistory = gql`
  mutation UpdateCargo(
    $id: ID!
    $shipment_history: [ID!]
    $current_shipment: String!
    $cargo_status: ID!
  ) {
    updateCargo(
      id: $id
      data: {
        shipment_history: $shipment_history
        current_shipment: $current_shipment
        cargo_status: $cargo_status
      }
    ) {
      data {
        attributes {
          cargo_status {
            data {
              id
            }
          }
          shipment_history {
            data {
              id
              attributes {
                description
                origin_country
                origin_address
                destination_country
                destination_address
                shipment_number
                shipment_method
                registration_date
                isFinished
                createdAt
                updatedAt
              }
            }
          }
          current_shipment
        }
      }
    }
  }
`;
const updateCargoStatus = gql`
  mutation UpdateCargo($id: ID!, $cargo_status: ID!) {
    updateCargo(id: $id, data: { cargo_status: $cargo_status }) {
      data {
        attributes {
          cargo_status {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const getAllCargoesId = gql`
  query Cargos {
    cargos(pagination: { limit: 10000 }) {
      data {
        id
      }
    }
  }
`;

type AllCargoesId = {
  cargos: {
    data: [
      {
        id: string;
      },
    ];
  };
};
type IdType = {
  id: string;
  isPending: boolean;
  pendingAuthor: string;
};
type ChangeInCargoPlaceStatus = {
  id: string;
  status: boolean;
};

type ChangeInCargoPlaceStatusAndAuthor = {
  id: string;
  status: boolean;
  pendingAuthor: string;
};

type ChangeCargoStatus = {
  id: string;
  current_shipment: string;
};

type UpdateShipmentHistory = {
  id: string;
  shipment_history: string[];
};

type Cargo = {
  data: {
    attributes: {
      shipment_history: {
        data: string[];
      };
    };
    id: string;
  };
};
export const useAllCargosId = () => {
  return useQuery<GraphQLResponse, Error, AllCargoesId>({
    queryKey: "cargoesId",
    queryFn: async () => {
      return graphqlRequestClient.request(getAllCargoesId);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetPendingCargoes = () => {
  return useQuery<GraphQLResponse, Error, CargoPendingQueryResponse>({
    queryKey: "pendingCargoes",
    queryFn: async () => {
      return graphqlRequestClient.request(pendingCargoes);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetPendingCargoesByAuthor = (pendingAuthor: string) => {
  return useQuery<GraphQLResponse, Error, CargoPendingQueryResponse>({
    queryKey: "pendingCargoes",
    queryFn: async () => {
      return graphqlRequestClient.request(pendingCargoesByAuthor, {
        pendingAuthor,
      });
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};
export const useCargos = () => {
  return useQuery<GraphQLResponse, Error, Data>({
    queryKey: "cargos",
    queryFn: async () => {
      return graphqlRequestClient.request(cargos);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetCargoByID = (id: string) => {
  return useQuery<GraphQLResponse, Error, CargoByID>({
    queryKey: "cargoByID",
    queryFn: async () => {
      return graphqlRequestClient.request(cargoByID, { id: id });
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useGetCargoesByCargoPlaceId = (id: string) => {
  return useQuery<GraphQLResponse, Error, CargoByID>({
    queryKey: "cargoesByCargoPlaceId",
    queryFn: async () => {
      return graphqlRequestClient.request(cargoesByCargoPlaceId, { id: id });
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};
export const useCargoMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<GraphQLResponse, Error, AddCargoFormData>(
    (data) => {
      return graphqlRequestClient.request(cargoMutation, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["cargos"] });
        navigate("/cargoes");
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};
export const useUpdageShipmentHistory = (data: any) => {
  return useMutation<GraphQLResponse, Error, any>((data) => {
    return graphqlRequestClient.request(shipmentHistoryByCargoId, data);
  });
};

export const useCargoPendingUpdatingById = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, IdType>(
    (data) => {
      return graphqlRequestClient.request(pendingCargoesStatus, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["pendingCargoes"] });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

export const useCargoCorrectionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, CargoFormProps>(
    (data) => {
      return graphqlRequestClient.request(cargoCorrectionMutation, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["cargos"] });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

export const useGetCargoPlaceTableItems = () => {
  return useQuery<GraphQLResponse, Error, CreateCargoPlaceTableItem>({
    queryKey: "CreateCargoPlaceTableItem",
    queryFn: async () => {
      return graphqlRequestClient.request(createCargoPlaceTableItem);
    },
    onError: (e) => {
      throw new Error(e.message);
    },
  });
};

export const useChangeInCargoPlaceStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, ChangeInCargoPlaceStatus>(
    (data) => {
      return graphqlRequestClient.request(changeInCargoPlaceStatus, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["CreateCargoPlaceTableItem"],
        });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

export const useChangeInCargoPlaceStatusAndAuthor = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, ChangeInCargoPlaceStatusAndAuthor>(
    (data) => {
      return graphqlRequestClient.request(
        changeInCargoPlaceStatusAndAuthor,
        data,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["CreateCargoPlaceTableItem"],
        });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

export const useChangeCurrentStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, ChangeCargoStatus>(
    (data) => {
      return graphqlRequestClient.request(changeCargoStatus, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["cargos"] });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

export const useUpdateShipmentHistory = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, UpdateShipmentHistory>(
    (data) => {
      return graphqlRequestClient.request(updateCargoShipmentHistory, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["cargos"] });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};

type UpdateCurrentShipmentAndHistory = {
  id: string;
  shipment_history: string[];
  current_shipment: string;
  cargo_status: string;
};

export const useUpdateCurrentShipmentAndHistoryAndCargoStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<GraphQLResponse, Error, UpdateCurrentShipmentAndHistory>(
    (data) => {
      return graphqlRequestClient.request(
        updateCargoCurrentShipmentAndHistory,
        data,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["cargos"] });
      },
      onError: (error) => {
        throw new Error(error.name + error.message);
      },
    },
  );
};
type cargoStatusType = {
  id: string;
  cargo_status: string;
};
export const useCargoStatusMutation = () => {
  return useMutation<GraphQLResponse, Error, cargoStatusType>((data) => {
    return graphqlRequestClient.request(updateCargoStatus, data);
  });
};
