import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "react-query";
import AddCargoPlacePage from "./views/cargoPlace/addCargoPlacePage";
import { ROUTES } from "./routesEnum";
// Containers
const CargoCorrection = React.lazy(
  () => import("./views/CargoCorrection/CargoCorrection"),
);
const AddUser = React.lazy(
  () => import("./views/users/service/components/addUser/addUser"),
);
const EditUser = React.lazy(
  () => import("./views/users/service/components/editUser/editUser"),
);
const Users = React.lazy(() => import("./views/users/Users"));
const Clients = React.lazy(() => import("./views/clients/Clients"));
const CargoDispatch = React.lazy(
  () => import("./views/cargoDispatch/cargoDispatch"),
);
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Login = React.lazy(() => import("./views/auth/Login"));
const Cargoes = React.lazy(() => import("./views/cargo/Cargoes"));
const Cargo = React.lazy(() => import("./views/cargo/Cargo"));
const AddCargo = React.lazy(() => import("./views/cargo/AddCargo"));
const Statuses = React.lazy(() => import("./views/status/statusesPage"));
const AddStatus = React.lazy(() => import("./views/status/addStatusPage"));
const EditStatus = React.lazy(() => import("./views/status/editStatusPage"));
const Shipment = React.lazy(() => import("./views/cargoDispatch/shipment"));
const ToDisbandShipment = React.lazy(
  () =>
    import(
      "./views/shipmentActivity/service/component/shipmentDisband/shipmentDisband"
    ),
);
const NewShipment = React.lazy(
  () =>
    import(
      "./views/shipmentActivity/service/component/shipmentDisband/newShipment"
    ),
);

const AddShipment = React.lazy(
  () => import("./views/cargoDispatch/addShipment"),
);
const CargoPlaceDetail = React.lazy(
  () => import("./views/cargoPlace/cargoPlaceDetailPage"),
);
const AddCargoPlace = React.lazy(
  () => import("./views/cargoPlace/addCargoPlacePage"),
);
const ShipmentActivity = React.lazy(
  () => import("./views/shipmentActivity/shipmentActivity"),
);
const DisbandCargoesTable = React.lazy(
  () =>
    import(
      "./views/shipmentActivity/service/component/shipmentTable/DisbandCargoesTable"
    ),
);

// const modalLogoutStatus = useTypedSelector((state) => state.modalLogout )

const client = new ApolloClient({
  uri: process.env.REACT_APP_GQL_BASE_URL,
  cache: new InMemoryCache(),
});

export const queryClient = new QueryClient();

const App = (): JSX.Element => {
  function RequireAuth({ children }: any) {
    if (!localStorage.jwt) {
      return <Navigate to="/login" />;
    }
    return children;
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Routes>
              <Route path={ROUTES.MAIN} element={<DefaultLayout />}>
                <Route index element={<Navigate to="/cargoes" />} />
                <Route path="*" element={<Navigate replace to="/cargoes" />} />
                <Route
                  path={ROUTES.ADD_CARGO}
                  element={
                    <RequireAuth>
                      <AddCargo />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.CARGOES}
                  element={
                    <RequireAuth>
                      <Cargoes />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.CARGO + ":id"}
                  element={
                    <RequireAuth>
                      <Cargo />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.EDIT_CARGO + ":id"}
                  element={
                    <RequireAuth>
                      <CargoCorrection />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.ADD_CARGO_PLACE}
                  element={
                    <RequireAuth>
                      <AddCargoPlacePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.CLIENTS}
                  element={
                    <RequireAuth>
                      <Clients />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.STATUSES}
                  element={
                    <RequireAuth>
                      <Statuses />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.USERS}
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/addStatus"
                  element={
                    <RequireAuth>
                      <AddStatus />
                    </RequireAuth>
                  }
                />

                <Route
                  path={ROUTES.SHIPMENT_ACTIVITY + ":id"}
                  element={
                    <RequireAuth>
                      <ShipmentActivity />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.DISBAND_CARGOES_TABLE + ":id"}
                  element={
                    <RequireAuth>
                      <DisbandCargoesTable />
                    </RequireAuth>
                  }
                />

                <Route
                  path={ROUTES.EDIT_USER + ":id"}
                  element={
                    <RequireAuth>
                      <EditUser />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.ADD_USER}
                  element={
                    <RequireAuth>
                      <AddUser />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.NEW_SHIPMENT + ":id"}
                  element={
                    <RequireAuth>
                      <NewShipment />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/shipmantDisband/:id"
                  element={
                    <RequireAuth>
                      <DisbandCargoesTable />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/statuses/editStatus/:id"
                  element={
                    <RequireAuth>
                      <EditStatus />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.SHIPMENTS}
                  element={
                    <RequireAuth>
                      <CargoDispatch />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.SHIPMENT + ":id"}
                  element={
                    <RequireAuth>
                      <Shipment />
                    </RequireAuth>
                  }
                />
                <Route
                  path={ROUTES.ADD_SHIPMENT}
                  element={
                    <RequireAuth>
                      <AddShipment />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/cargoPlaceDetail/:id"
                  element={
                    <RequireAuth>
                      <CargoPlaceDetail />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/cargoDispatch/shipment/cargoPlaceDetail/:id"
                  element={
                    <RequireAuth>
                      <CargoPlaceDetail />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/cargo/:id"
                  element={
                    <RequireAuth>
                      <Cargo />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/cargoDispatch/shipment/cargoPlaceDetail/cargo/:id"
                  element={
                    <RequireAuth>
                      <Cargo />
                    </RequireAuth>
                  }
                />
              </Route>

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Suspense>
        </ApolloProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
