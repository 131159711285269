import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { tableFontSizes } from "../../shared/tableFontSize";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import {
  useDeleteCargoPlace,
  useGetFreeCargoPlaces,
  useGetFreeCargoPlacesByAuthor,
} from "../../features/cargoPlace/api/cargoplace.api";
import { useNavigate } from "react-router-dom";

type SelectedCargos = {
  id: string;
  cargos: { id: string }[];
};
export const AddCargoPlaceTable = () => {
  const navigate = useNavigate();
  const [selectedCargos, setSelectedCargos] = useState<SelectedCargos[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const { data } = useGetFreeCargoPlacesByAuthor();

  const { mutate } = useDeleteCargoPlace();
  const deleteCargoPlace = () => {
    selectedCargos.forEach((item) =>
      mutate({ id: item.id, cargos: item.cargos }),
    );
  };

  return (
    <Fragment>
      <CRow className={"mt-5"}>
        <h5>Грузовые места</h5>
        <CCol>
          <CSmartTable
            columnFilter
            columnSorter
            columns={[
              {
                key: "select",
                label: "",
                sorter: false,
                filter: false,
                _style: { fontSize: tableFontSizes.HEADER },
              },
              {
                key: "number",
                label: "Номер грузового места",
                _style: { fontSize: tableFontSizes.HEADER },
              },
              {
                key: "type",
                label: "Вид грузового места",
                _style: { fontSize: tableFontSizes.HEADER },
              },
              {
                key: "registration_date",
                label: "Дата оформления",
                _style: { fontSize: tableFontSizes.HEADER },
              },
            ]}
            items={data?.logisticHubs?.data?.map((place) => ({
              number: place.attributes.number,
              type: place.attributes.place_type,
              registration_date: dayjs(place.attributes.createdAt).format(
                "DD/MM/YYYY",
              ),
              select: "",
              id: place.id,
              cargos: place.attributes.cargos.data,
            }))}
            clickableRows={true}
            itemsPerPageSelect
            pagination
            noItemsLabel={"Грузы не найдены"}
            itemsPerPageLabel={"Показать: "}
            tableProps={{
              hover: true,
              responsive: true,
            }}
            selectable={true}
            onSelectedItemsChange={(items) => {
              setSelectedCargos(
                items.map((item) => ({ id: item.id, cargos: item.cargos })),
              );
            }}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={3}>
          <CButton variant={"outline"} onClick={() => setVisible(true)}>
            Расформировать грузовые места
          </CButton>
        </CCol>
        <CCol xs={4}>
          <CButton variant={"outline"} onClick={() => navigate("/addShipment")}>
            Вернуться к формированию отправления
          </CButton>
        </CCol>
      </CRow>
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader onClick={() => setVisible(false)}>
          <CModalTitle>Расформирование грузовых мест?</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Расформировать грузовые места?</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Нет
          </CButton>
          <CButton
            color="danger"
            onClick={() => {
              deleteCargoPlace();
              setVisible(false);
            }}
          >
            Да
          </CButton>
        </CModalFooter>
      </CModal>
    </Fragment>
  );
};
