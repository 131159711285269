import React, { Fragment, useState } from "react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useGetCargoPlaceTableItems } from "../cargo/api/cargo.api";
import { paidBadge } from "../../shared/utils/paidBadge";
import {
  useAddCargoPlace,
  useAddCargoPlaceAndAuthor,
} from "../../features/cargoPlace/api/cargoplace.api";
import { Controller, useForm } from "react-hook-form";
import { AddCargoPlaceTable } from "./addCargoPlaceTable";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addCargoPlaceSchema,
  CreateCargoPlaceCargoItem,
} from "../../entities/cargo/model/cargo.model";
import { columnNames } from "./service/general/columnNames";

type FormProps = {
  type: string;
  number: string;
};

const AddCargoPlacePage = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: zodResolver(addCargoPlaceSchema),
  });
  const { data } = useGetCargoPlaceTableItems();
  const [selectedCargos, setSelectedCargos] = useState<string[]>([]);
  const { mutate } = useAddCargoPlaceAndAuthor();
  const [selected, setSelected] = useState<CreateCargoPlaceCargoItem[]>([]);
  const author = localStorage.getItem("userName");
  const addCargoPlace = (data: FormProps) => {
    mutate({
      number: data.number,
      type: data.type,
      cargos: selectedCargos,
      author: author as string,
    });
    setSelectedCargos([]);
    setSelected([]);
    reset();
  };

  return (
    <Fragment>
      <CRow className={"mt-5"}>
        <CCol>
          <h2>Выберите грузы и создайте грузовые места</h2>
        </CCol>
      </CRow>
      <CRow className="mt-5">
        <CCol>
          <CSmartTable
            columnFilter
            columnSorter
            columns={columnNames}
            items={data?.cargos?.data?.map((cargo) => ({
              select: "",
              id: cargo.id,
              track_number: cargo.attributes.track_number,
              cargo_title: cargo.attributes.cargo_title,
              cargo_status: cargo.attributes?.cargo_status?.data?.attributes,
              registration_date: cargo.attributes.registration_date,
              destination:
                cargo.attributes.destination_county +
                ", " +
                cargo.attributes.destination_address,
              price: cargo.attributes.price,
              paid: cargo.attributes.paid,
            }))}
            scopedColumns={{
              paid: (item: { paid: boolean }) => paidBadge(item.paid),
              cargo_status: (item: any) => {
                return (
                  <td>
                    <CBadge
                      style={{
                        backgroundColor: `${
                          item?.cargo_status?.color
                            ? item?.cargo_status?.color
                            : "gray"
                        }`,
                      }}
                    >
                      {item?.cargo_status != null
                        ? item?.cargo_status?.name +
                          `-` +
                          item?.cargo_status?.country
                        : "Груз не выдан"}
                    </CBadge>
                  </td>
                );
              },
            }}
            clickableRows={true}
            itemsPerPageSelect
            pagination
            noItemsLabel={"Грузы не найдены"}
            itemsPerPageLabel={"Показать: "}
            tableProps={{
              hover: true,
              responsive: true,
            }}
            selectable={true}
            onSelectedItemsChange={(items) => {
              setSelected(items);
              setSelectedCargos(items.map((item) => item.id));
            }}
            selected={selected}
          />
        </CCol>
      </CRow>
      <CRow className={"mb-5 mt-4"}>
        <CCol>
          <CCard>
            <CCardBody>
              <CForm onSubmit={handleSubmit(addCargoPlace)}>
                <CRow className="d-flex gap-2 justify-content-around">
                  <CCol>
                    <CButton
                      type={"submit"}
                      variant={"outline"}
                      disabled={selectedCargos.length < 1}
                      onClick={() => handleSubmit(addCargoPlace)}
                    >
                      Создать грузовое место
                    </CButton>
                  </CCol>
                  <CCol>
                    <CRow
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                    >
                      <CCol xs={4}>
                        <CFormLabel>Тип места</CFormLabel>
                      </CCol>
                      <CCol>
                        <Controller
                          control={control}
                          render={({ field: { onChange } }) => (
                            <CFormSelect onChange={onChange}>
                              <option>Тип места</option>
                              <option value="Палета">Палета</option>
                              <option value="Мешок">Мешок</option>
                              <option value="Сумка">Сумка</option>
                              <option value="Ящик">Ящик</option>
                            </CFormSelect>
                          )}
                          name={"type"}
                        />
                        {errors?.type && (
                          <div className={"text-danger h-6"}>
                            Поле является обязательным
                          </div>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol>
                    <CRow
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                    >
                      <CCol xs={4}>
                        <CFormLabel>Номер места</CFormLabel>
                      </CCol>
                      <CCol>
                        <CFormInput {...register("number")} />
                        {errors?.number && (
                          <div className={"text-danger h-6"}>
                            {errors?.number?.message}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
        <AddCargoPlaceTable />
      </CRow>
    </Fragment>
  );
};

export default AddCargoPlacePage;
